<template>
  <pool title="人员类别" @chooseCount="chooseCount" :dataList="showDataList" :countList="countList" v-loading="loading"></pool>
</template>

<script>
import {
  loadDetail,
  detailFilter,
  getCountData
} from './lib'

import pool from './pool'
export default {
  data () {
    return {
      dataList: [],
      searchParm: {
        jobType: null
      },
      loading: false
    }
  },
  components: {
    pool
  },
  computed: {
    typeList () {
      return this.$store.getters.selectDatas['worker_job_type'].map(v => {
        return {
          name: v.label
        }
      })
    },
    showDataList () {
      return this.dataList.filter(v => detailFilter(v, this.searchParm))
    },
    countList () {
      let data = getCountData(this.dataList, this.typeList, 'jobType', '无人员类别')
      data.forEach(v => v.active = this.searchParm.jobType === v.key)
      return data
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    chooseCount (cIndex) {
      let jobType = this.countList[cIndex].key
      if (this.searchParm.jobType === jobType) {
        this.searchParm.jobType = null
      } else {
        this.searchParm.jobType = jobType
      }
    },
    async loadData () {
      this.loading = true
      this.dataList = await loadDetail(this)
      this.loading = false
    }
  }
}
</script>
